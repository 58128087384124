
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../elements/Button';
import { ClearIcon } from './ClearIcon';
import { ClearConfig } from './types';

type ClearButtonProps = {
    config: ClearConfig,
    width: number;
    onClick?: () => void;
    newSession?: boolean;
}

// CLEAR styling UX requirements. See: https://docs.clearme.com/docs/ux-guidelines
const theme = {
    backgroundColor: '#041A55',
    hoverBackgroundColor: '#394168',
    fontFamily: 'Inter',
}

/**
 * A button to navigate to the CLEAR Hosted UI for identity verification.
 * @param config - The configuration for the CLEAR vendor.
 * @param width - The width of the button (in pixels).
 * @param onClick - A callback function to execute when the button is clicked.
 * @param newSession - A boolean indicating whether to start a new session or continue an existing session with CLEAR.
 */
export const ClearButton: React.FC<ClearButtonProps> = ({
    config,
    newSession = true,
    onClick,
    width
}: ClearButtonProps) => {
    const {t} = useTranslation();
    const text = newSession ? 'Verify with CLEAR' : 'Continue with CLEAR';

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
        window.location.replace(config.verifyUrl);
    }

    return (
        <Button
            variant="contained" 
            activeBackgroundColor={theme.hoverBackgroundColor}
            backgroundColor={theme.backgroundColor}
            fontFamily={theme.fontFamily}
            hoverBackgroundColor={theme.hoverBackgroundColor}
            paddingLeft={0}
            paddingRight={0}
            startIcon={<ClearIcon logoUrl={config.logoUrl} />}
            textTransform='none'
            width={width}
            onClick={handleClick}
        >
            {t(text)}
        </Button>
    );
}
