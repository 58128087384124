import axios, { AxiosResponse } from "axios";
import { getClientContext, toQueryString } from "../util/clientContextUtils";
import { ClientContext } from "../../clientConfig";

type CallbackType<T> = (response: AxiosResponse, clientContext: ClientContext) => T;

export function fetchClientConfiguration<T>(callback: CallbackType<T>): Promise<T | void> {

    const clientContext = getClientContext();
    const queryString = toQueryString(clientContext);

    const path = '/api/config';
    const url = queryString ? `${path}?${queryString}` : path;

    return axios.get(
        url,
        {
            headers: {
                'Cache-Control': 'private, max-age=300'
            }
        }
    )
    .then((response) => callback(response, clientContext))
    .catch((err: any) => console.error(err));
}
