import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { getI18n, Trans } from 'react-i18next';
import { type BrandConfig} from "../../../clientConfig";

export default function checkboxControl(label: any, setTermsAccepted: any) {
    return (
        <FormControlLabel
            control={<Checkbox defaultChecked={false} required onChange={(event) => setTermsAccepted(event.target.checked)}/>}
            name="checkbox"
            label={label}
            className="checkbox"
        />);
}

export function privacyPolicyCheckboxLabel(brandInfo: BrandConfig) {
    const currentLanguage = getI18n().language;
    const lang=['es','espanol'].includes(currentLanguage)?"es":"en";
    const privacyPolicyUrl = brandInfo.links.privacyPolicy + "&lang=" + lang;
    const termsOfUseUrl = brandInfo.links.termsAndConditions + "&lang=" + lang;
    return (
        <div>
            <Typography variant="subtitle1">
                <Trans values={{privacyPolicyUrl:privacyPolicyUrl, termsOfUseUrl:termsOfUseUrl}}>
                    {"I have read, understand, and agree to the "}
                    <a href={privacyPolicyUrl} className="txtDecorationNone">
                        Privacy Policy
                    </a>
                    {", the "}
                    <a href={termsOfUseUrl} className="txtDecorationNone">
                        Terms of Use.
                    </a>
                </Trans>
            </Typography>
        </div>
    );
}
