import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import getAccessToken from "../../../util/getAccessToken";
import BrandedCard from '../../containers/BrandedCard/BrandedCard';
import MfaEmailForm from './MfaEmailForm';
import TwoButtonContainer from '../../containers/TwoButtonContainer';
import { type BrandProps } from "../../../../clientConfig";
import { sendVerificationCode } from '../../../services/pimsClient';
import { ClientConfigContext } from "../../../clientConfigContext";
import { ClientConfigType } from "../../../../clientConfig";
import { useMsal } from '@azure/msal-react';
import Icon from '../../elements/Icon';
import withLogger, { ComponentLoggerProps } from '../../HOC/WithLogger';
import { MFAVerifyType } from '../../../../types/mfa';

type MfaByEmailPageFormValues = {
    email: string,
}

function MfaByEmailPage({ brandInfo, log }: BrandProps & ComponentLoggerProps) {
    const history = useHistory();
    const { instance, accounts } = useMsal();
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const { handleSubmit, errors, control, formState, trigger, getValues } = useForm({ mode: 'onChange' });

    const { t, i18n } = useTranslation();

    const { isDirty, isValid } = formState;

    const onSubmit: SubmitHandler<MfaByEmailPageFormValues> = async (data) => {
        log.info("Mfa Email Method: Sending verification code.");
        const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log);
        try {
            const response = await sendVerificationCode({
                jwt, value: data.email, pimsApiUrl: clientConfiguration.pimsApiUrl, language: i18n.languages[0], mode: MFAVerifyType.EmailVerifyType
            });
            if (response.ok) {
                log.info("Mfa Email Method: Sent verification code successfully.");
                history.push({
                    pathname: "/mfa-verification-code",
                    state: { emailId: data.email }
                });
            } else {
                log.error(`Mfa Email Method: Sent verification code fail. Error occurred - ${response.status}${response.statusText ? ` -  ${response.statusText}`: ''}`);
                history.push("/error");
            }
        } catch (err: any) {
            log.error(`Error occurred - ${err.status}`);
            history.push("/error");
        }
    }


    return (
        <>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <BrandedCard brand={brandInfo}>
                        {{
                            header: (<>
                                <Grid item container spacing={3} >
                                    <Grid item xs={12}>
                                        <Icon size={63.74} iconUri={`${clientConfiguration.cdnPath}${clientConfiguration.mfaConfig.securityIconUri}`} altText={""} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            {t("Enter your email address")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>),
                            content: (<>
                                <MfaEmailForm control={control} errors={errors} trigger={trigger} getValues={getValues} />
                            </>),
                            actions: (<>
                                <TwoButtonContainer
                                    firstButtonProps={{ variant: "text", label: t("SET UP LATER"), link: "/", brand: brandInfo.brand }}
                                    secondButtonProps={{ variant: "contained", disabled: !isDirty || !isValid, label: t("SEND CODE"), brand: brandInfo.brand }}
                                />
                            </>)
                        }}
                    </BrandedCard>
                </form>
            </div>
        </>
    );
}

export default withLogger(MfaByEmailPage, { eventSource: "MfaByEmailPage"});
