import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { ReactNode } from 'react';

const useStyles = makeStyles<Theme, BrandedCardContentStyles, string>(() => ({
    root: {
        alignItems: "center",
        minHeight: 286,
        justifyContent: "center",
        marginTop: options => options.marginTop,
        paddingLeft: options => options.paddingLeft,
        paddingRight: options => options.paddingRight,
    },
}));

type BrandedCardContentStyles = {
    marginTop: number | string;
    paddingLeft: number;
    paddingRight: number;
}

export type BrandedCardContentProps = {
    children: ReactNode | ReactNode[],
    marginTop?: number
    paddingLeft?: number;
    paddingRight?: number;
}

const BrandedCardContent = (props: BrandedCardContentProps) => {
    const classes = useStyles({
        marginTop: props.marginTop ?? 'inherit',
        paddingLeft: props.paddingLeft ?? 60,
        paddingRight: props.paddingRight ?? 60
    });
    const { children } = props;

    return (
        <Grid item className={classes.root} xs={12}>
            {children}
        </Grid>
    );
}
BrandedCardContent.displayName = 'BrandedCardContent';
export default BrandedCardContent;

