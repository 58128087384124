import React from 'react';
import { useTranslation, getI18n } from 'react-i18next';
import { Grid, Link } from '@material-ui/core';

import { type BrandProps, type LegalLinkURLs} from "../../../clientConfig";

function LegalLinks({ brandInfo }: BrandProps) {

    const {t} = useTranslation();
    const currentLanguage = getI18n().language;
    const lang = ['es', 'espanol'].includes(currentLanguage) ? "es" : "en";

    function getLinks(): LegalLinkURLs {
        return {
            faq: brandInfo.links.faq + '&lang=' + lang,
            privacyPolicy: brandInfo.links.privacyPolicy + '&lang=' + lang,
            termsAndConditions: brandInfo.links.termsAndConditions + '&lang=' + lang,
            nonDiscrimination: brandInfo.links.nonDiscrimination + '&lang=' + lang,
        };
    }

    const links = getLinks();
    return (
        <>
            <Grid item xs={12}>
                &nbsp;
            </Grid>
            <Grid container justify="center" item xs={8} sm={12} className="txtAlignCenter">
                <Link className="legalLinksText" href={links.privacyPolicy} color="textSecondary">
                    {t("Privacy Policy")}
                </Link>
                <Link className="legalLinksText" href={links.faq} color="textSecondary">
                    {t("FAQs")}
                </Link>
                <Link className="legalLinksText" href={links.termsAndConditions} color="textSecondary">
                    {t("Terms and Conditions")}
                </Link>
                <Link className="legalLinksText" href={links.nonDiscrimination} color="textSecondary">
                    {t("Non-discrimination")}
                </Link>
            </Grid>
            <Grid item xs={12}>
                &nbsp;
            </Grid>
        </>
    );
}

export default LegalLinks;
