import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';

import BrandedCard from '../../../containers/BrandedCard/BrandedCard';
import { Button } from '../../../elements/Button';
import {BrandProps, ClientConfigType} from "../../../../../clientConfig";
import { sendEventbyIdVerification, sendEventByType } from '../../../../services/amplitudeClient';
import { useMsal } from '@azure/msal-react';
import { ClientConfigContext } from '../../../../clientConfigContext';
import getAccessToken from '../../../../util/getAccessToken';
import {LocationState} from "../../../../../types/reactTypes";
import withLogger, { ComponentLoggerProps } from '../../../HOC/WithLogger';
import { AmplitudeEventType } from '../../../../../analytics/amplitudeEvents';

function CollectDemographicsIntro({ brandInfo, log }: BrandProps & ComponentLoggerProps) {
    const {t} = useTranslation();
    const location = useLocation<LocationState>();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [proveBypass, setProveBypass] = useState("");
    const [jwt, setJwt] = useState("");
    const {instance, accounts} = useMsal();
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const history = useHistory();
    const onEnteringInformation= () =>{
        sendEventByType(AmplitudeEventType.IdentityVerification_DemographicForm_Back, jwt);
        history.push("/demographics", {
            phoneNumber: phoneNumber,
            proveBypass: proveBypass,
            isCrossRegion: location?.state?.isCrossRegion
        });
    }

    useEffect(()=>{
        getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log).then((jwt)=>{
            setJwt(jwt);
             sendEventbyIdVerification(jwt, AmplitudeEventType.IdentityVerification_NumberVerified,'sms');
        });
    },[]);
    useEffect(() => {
        setPhoneNumber(location?.state?.phoneNumber);
        setProveBypass(location?.state?.proveBypass);
    }, [location]);

    return (
        <>
            <BrandedCard brand={brandInfo} >
                {{
                    header: (<>
                        <Typography variant="h2">
                            {t('Your phone number was verified')}
                        </Typography>
                    </>),
                    content: (<>
                        <Grid container justify="center" item xs={10} className="txtAlignCenter">
                            <Typography variant="h3">
                                {t('We need to collect demographics information about you.')}
                            </Typography>
                        </Grid>
                    </>),
                    actions: (
                        <>
                            <Grid container justify="center" item xs={12}>
                                <Grid container justify="center" item xs={10}>
                                    <Button type="button" variant="contained" onClick={onEnteringInformation}>{t('Enter My Information')}</Button>
                                </Grid>
                            </Grid>
                        </>
                    )
                }}
            </BrandedCard>
        </>
    );
}

export default withLogger(CollectDemographicsIntro, {eventSource: 'CollectPhoneNumberForm'});
