import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

import BrandedCard from '../../containers/BrandedCard/BrandedCard';
import {BrandProps} from "../../../../clientConfig";

function VerifyCreatingYourAccount({ brandInfo }: BrandProps) {
    const {t} = useTranslation();
    return (
        <>
        <BrandedCard brand={brandInfo}>
            {{
                header: (<>
                    <Typography variant="h2">
                        {t('Creating your account')}
                    </Typography>
                </>),
                content: (<>
                    <Grid item xs={12}>&nbsp;</Grid>
                    <Grid container justify="center" item xs={12}>
                        <CircularProgress />
                    </Grid>
                </>)
            }}
        </BrandedCard>
        </>
    );
}

export default VerifyCreatingYourAccount;
