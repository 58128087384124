import { useEffect, useState } from "react";

const getCookie = (name: string): string | undefined => {
    if (document.cookie) {
        const cookies: string[] = document.cookie.split(';');
        const foundCookie = cookies.find((cookie) => cookie.trim().startsWith(`${name}=`));
        return foundCookie?.split("=")?.[1];
    }
}

/**
 * Very simple wrapper around document.cookie to get and set cookies
 * @param name Name of the cookie
 * @returns The value of the cookie and a setter function
 */
const useCookie = (name: string): [string | undefined, typeof setCookie] => {
    const [value, setValue] = useState<string>();

    const setCookie = (value: string) => {
        document.cookie = `${name}=${value}; Path=/;`
        setValue(value);
    }
    
    useEffect(() => {
        const cookieValue = getCookie(name);
        setValue(cookieValue);
        
    }, [name, document.cookie]);

    return [ value, setCookie ];
}

export default useCookie;
