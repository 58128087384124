import React, {useState, useEffect, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core'
import { type ClientConfigType } from "../../../clientConfig";
import {useMsal} from "@azure/msal-react";
import {OPENID_PARAMS} from "../../../types/openid_enum";
import {ClientConfigContext} from "../../clientConfigContext";
import getAccessToken from "../../util/getAccessToken";
import {sendEventbyIdVerification, sendEventByPhoneNumber} from "../../services/amplitudeClient";
import {formatPhoneNumber} from "../../../utils/formatPhoneNumber";
import {isRedirectUrlValid} from "../../services/validation";
import Button from "../elements/Button";
import BrandedCard, { BrandedCardActions, BrandedCardContent, BrandedCardHeader } from "./BrandedCard_v2";
import {redirectWithSoftLogout} from "../../util/sessionTimeout";
import { useErrorLogging } from '../../util/errorUtils';
import TypographyI18N from '../elements/TypographyI18N';
import withLogger, { ComponentLoggerProps } from '../HOC/WithLogger';
import { AmplitudeEventType } from '../../../analytics/amplitudeEvents';

function VerifyContactCustomerService({ log }: ComponentLoggerProps) {
    const searchParams = new URLSearchParams(document.location.search);
    const isMobile = searchParams.get(OPENID_PARAMS.MOBILE) === 'true';
    const clientLandingPageUrl = searchParams.get(OPENID_PARAMS.CANCEL_REDIRECT_URI);
    const {t} = useTranslation();
    const [validRedirectUrl, setValidRedirectUrl] = useState(false);
    const [jwt, setJwt] = useState("");
    const {instance, accounts} = useMsal();
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    useErrorLogging(log);

    useEffect(() => {
        window.scrollTo(0, 0);
        validateRedirectParameter();

        getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log).then((jwt) => {
            setJwt(jwt);
            sendEventbyIdVerification(jwt, AmplitudeEventType.IdentityVerification_ContactService, 'sms');
        });
    }, []);

    const handleLinkClick = () => {
        sendEventByPhoneNumber(jwt, AmplitudeEventType.IdentityVerification_ContactService_Call, 'sms', formatPhoneNumber(clientConfiguration.customerSupportNumber));
    };
    const validateRedirectParameter = async () => {
        let isValid = false;
        if (clientLandingPageUrl) {
            isValid = await isRedirectUrlValid(clientLandingPageUrl);
        }
        setValidRedirectUrl(isValid);
    };
    const handleCloseButtonClick = () => {
        sendEventbyIdVerification(jwt, AmplitudeEventType.IdentityVerification_ContactService_Close, 'sms');
        redirectWithSoftLogout(instance, clientLandingPageUrl as string);
    };

    // Mobile will provide a back button for the user to touch, don't display the Close button
    const showCloseButton = !isMobile && clientLandingPageUrl && validRedirectUrl;

    return (
        <BrandedCard>
            <BrandedCardHeader>
                <TypographyI18N variant="h2">Contact customer service</TypographyI18N>
            </BrandedCardHeader>
            <BrandedCardContent>
                <Typography variant="body1">
                    {t('We could not verify your identity. To access your medical records, call customer service toll-free at')}<br/>
                    <a href={"tel:" + clientConfiguration.customerSupportNumber} onClick={handleLinkClick}> {formatPhoneNumber(clientConfiguration.customerSupportNumber)} </a>.
                </Typography>
            </BrandedCardContent>
            <BrandedCardActions>
                { showCloseButton && <Button type="button" variant="contained" onClick={handleCloseButtonClick}>{t('Close')}</Button> }
            </BrandedCardActions>
        </BrandedCard>
    );
}

export default withLogger(VerifyContactCustomerService, { eventSource: "VerifyContactCustomerService"});
