import React from 'react';
import { MemoryRouter, Route, Switch } from 'react-router-dom';
import UsernameRecoveryPage from '../features/usernameRecovery/UsernameRecoveryPage';
import UsernameRecoveryHandOff from '../features/usernameRecovery/UsernameRecoveryHandOff';
import VerifyContactCustomerService from '../containers/VerifyContactCustomerService';
import AutoInstantActivationLandingPage from '../features/autoinstantactivation/AutoInstantActivationLandingPage';
import AutoInstantActivationErrorPage from '../features/verification/AutoInstantActivationErrorPage';
import { useBrand } from '../../hooks/useBrand';
import { UnauthenticatedRoutes } from '.';

const UnauthenticatedRouter = (): JSX.Element => {
    const brandInfo = useBrand();

    const {
        RootRoutes,
        UsernameRecoveryRoutes
    } = UnauthenticatedRoutes

    return (
        <Switch>
            <Route exact path={RootRoutes.UsernameRecovery}>
                <MemoryRouter>
                    <Switch>
                        <Route exact path={UsernameRecoveryRoutes.Start}>
                            <UsernameRecoveryPage brandInfo={brandInfo}/>
                        </Route>
                        <Route path={UsernameRecoveryRoutes.HandOff}>
                            <UsernameRecoveryHandOff brandInfo={brandInfo}/>
                        </Route>
                        <Route path={UsernameRecoveryRoutes.ContactCustomerService}>
                            <VerifyContactCustomerService />
                        </Route>
                    </Switch>
                </MemoryRouter>
            </Route>
            <Route exact path={RootRoutes.AutoInstantActivationLandingPage}>
                <AutoInstantActivationLandingPage />
            </Route>
            <Route path={RootRoutes.AutoInstantActivationInvalidLink}>
                <AutoInstantActivationErrorPage />
            </Route>
        </Switch>
    );
}

export default UnauthenticatedRouter;
