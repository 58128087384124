import React from "react";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    iconRoot: {},
    imageIcon: {
        display: 'flex',
        height: 'inherit',
        width: 'inherit'
    }
});

type ClearIconProps = {
    logoUrl: string;
}

/**
 * The CLEAR logo icon (for use in the CLEAR button).
 * @param logoUrl - The URL of the CLEAR logo
 */
export const ClearIcon: React.FC<ClearIconProps> = ({logoUrl}: ClearIconProps) => {
    const classes = useStyles();
    return (
        <Icon classes={{root: classes.iconRoot}}>
            <img className={classes.imageIcon} src={logoUrl} />
        </Icon>
    );
}
