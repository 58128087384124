
export type RedirectQueryParams = { [key: string]: boolean | string };

export type GetPageFn = (ehrSystem: string, brand: string) => string;

export type GetRedirectUrlOptions = {
    brand: string;
    deviceType?: string;
    ehrSystem: string;
    getBaseURL: GetPageFn;
    isCrossRegion?: boolean;
    queryParams?: RedirectQueryParams;
}

/**
 * Builds a redirect URL for MyChart based on the provided options.
 * TODO: Don't try-catch, use if-else
 */
export const getRedirectUrl = (options: GetRedirectUrlOptions) => {
    const { brand, deviceType, ehrSystem, getBaseURL, isCrossRegion, queryParams } = options;

    const baseUrlString = getBaseURL(ehrSystem, brand);
    const baseUrl = new URL(baseUrlString);
    const urlSearchParams = new URLSearchParams(baseUrl.searchParams);

    // [SPI-2087] We found that MyChart mobile does not work with Cross Region.
    if (deviceType === 'mobile' && !isCrossRegion) {
        urlSearchParams.set('mobile', 'true');
    }

    for (const key in queryParams) {
        urlSearchParams.set(key, queryParams[key].toString());
    }

    if (urlSearchParams.toString()) {
        return `${baseUrl.origin}${baseUrl.pathname}?${urlSearchParams}`;
    } else {
        return baseUrl.toString();
    }
}
