import { ThemeOptions } from "@material-ui/core";
  
export const theme: ThemeOptions = {
    palette: {
        common: {
            black: "#202020",
            white: "#FFFFFF"
        },
        primary: {
            dark: '#1F3831',
            main: "#1C6712", // medium
            light: "#39892F"
        },
        secondary: {
            dark: '#002363',
            main: "#00338E", // medium
            light: "#335BA4"
        },
        background: {
            default: "#FFFFFF",
            paper: "#FFFFFF"
        }
    },
    typography: {
        h1: { // Figma "Big Title"
            fontFamily: 'Barlow',
            fontSize: 33,
            fontWeight: 400,
            color: '#202020',
        },
        h2: { // Figma "Title"
            fontFamily: 'Barlow',
            fontSize: 20,
            fontWeight: 500,
            color: '#202020',
        },
        subtitle1: { // Figma "Subtitle"
            fontFamily: 'Barlow',
            fontSize: 20,
            fontWeight: 400,
            color: '#202020',
            lineHeight: 'normal'
        },
        body1: { // Figma "Body 1"
            fontFamily: 'Barlow',
            fontSize: 16,
            fontWeight: 400,
            color: '#202020',
        },
        h3: { // Figma "Body 1 Highlight"
            fontFamily: 'Barlow',
            fontSize: 16,
            fontWeight: 600,
            color: '#202020',
        },
        body2: { // Figma "Body 2"
            fontFamily: 'Barlow',
            fontSize: 14,
            fontWeight: 400,
            color: '#202020',
        },
        h4: { // Figma "Banner Header"
            fontFamily: 'Barlow',
            fontSize: 14,
            fontWeight: 500,
            color: '#000000',
        },
        h5: { // Figma "Banner Subtitle"
            fontFamily: 'Barlow',
            fontSize: 12,
            fontWeight: 400,
            color: '#3E3E3E',
        },
        h6: { // Figma "Banner Ratings"
            fontFamily: 'Barlow',
            fontSize: 10,
            fontWeight: 400,
            color: '#3E3E3E',
        },
    },
    overrides: {
        MuiTypography: {
            colorTextSecondary: {
                color: '#FFFFFF',
            }
        },
        MuiFormHelperText: {
            root: {
                height: "40px",
            }
        },
        // This is a hack to allow placeholders to be visible in webkit. It's a known issue in MUI v4
        MuiFormControl: {
            root: {
                '& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder ': {
                    opacity: "1 !important"
                }
            }
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            // All values are the defaults except for "sm"
            // It is set to 450px to match style.css
            sm: 450,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
};
