import React, {useState, useContext, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import {useHistory} from 'react-router-dom';

import BrandedCard from '../../containers/BrandedCard/BrandedCard';
import { Button } from '../../elements/Button';
import ChangePasswordForm from './ChangePasswordForm';
import { changePassword } from '../../../services/pimsClient';
import {ClientConfigContext} from "../../../clientConfigContext";
import {isRedirectUrlValid} from "../../../services/validation";
import getAccessToken from "../../../util/getAccessToken";
import {useMsal} from "@azure/msal-react";
import {BrandProps, ClientConfigType} from "../../../../clientConfig";
import {OPENID_PARAMS} from "../../../../types/openid_enum";
import withLogger, { ComponentLoggerProps } from '../../HOC/WithLogger';

function ChangePasswordPage({ brandInfo, log }: BrandProps & ComponentLoggerProps): JSX.Element {
    const {instance, accounts} = useMsal();
    const history = useHistory();
    const { handleSubmit, errors, control, formState, trigger, getValues } = useForm({ mode: 'onBlur' });
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const [loading, setLoading] = useState(false);
    const search = document.location.search;
    const searchParams = new URLSearchParams(search);
    const redirectUrl = searchParams.get(OPENID_PARAMS.REDIRECT_URI) || searchParams.get(OPENID_PARAMS.REDIRECT_URL) as string;
    const { isDirty, isValid } = formState;
    const {t, i18n} = useTranslation();

    const onSubmit = async (data: any) => {
        setLoading(true);
        try {
            const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log);
            const response = await changePassword({
                jwt,
                existingpassword: data.existingPassword,
                newpassword: data.password,
                confirmpassword: data.confirmPassword,
                pimsApiUrl: clientConfiguration.pimsApiUrl,
                language: i18n.languages[0],
                brand: brandInfo.brand
            });
            if (response.ok) {
                window.location.replace(redirectUrl);
            } else {
                log.error(`Error occurred - ${response.status}${response.statusText ? ` -  ${response.statusText}`: ''}`);
                history.push("/error");
            }
        } catch (error) {
            log.error(`Error occurred - ${error}`);
            history.push("/error");
        }
    };

    const renderHeader = () => {
        if (loading) {
            return (
                <>
                    <Typography variant="h2">
                        {t('Updating password')}
                    </Typography>
                </>
            );
        } else {
            return (
                <>
                    <Typography variant="h2">
                        {t('Change your password')}
                    </Typography>
                </>
            );
        }
    };

    const renderContent = () => {
        if (loading) {
            return (
                <>
                    <Grid item xs={12}>&nbsp;</Grid>
                    <Grid container justify="center" item xs={12}>
                        <CircularProgress />
                    </Grid>
                </>
            );
        } else {
            return (
                <ChangePasswordForm control={control} errors={errors} getValues={getValues} trigger={trigger} />
            );
        }
    };

    const renderActions = () => {
        if (loading) {
            return (
                <>
                </>
            );
        } else {
            return (
                <Grid container justify="center" item xs={12}>
                    <Button disabled={!isDirty || !isValid} type="submit" variant="contained" width={200}>{t('SAVE PASSWORD')}</Button>
                </Grid>
            );
        }
    };
    const validateInputParameters = async () => {
        if (!redirectUrl) {
            log.warn( `Missing redirect_uri`);
            history.push("/error");
        } else {
            const isValid = await isRedirectUrlValid(redirectUrl);
            if (!isValid) {
                log.warn(`invalid redirect_uri provided`);
                history.push("/error");
            }
        }
    };

    useEffect(() => {
        validateInputParameters();
    },[]);

    return(
        <>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <BrandedCard brand={brandInfo} >
                        {{
                            header: (renderHeader()),
                            content: (<>
                                {renderContent()}
                            </>),
                            actions: (<>
                                {renderActions()}
                            </>)
                        }}
                    </BrandedCard>
                </form>
            </div>
        </>
    );
}

export default withLogger(ChangePasswordPage, { eventSource: "ChangePasswordPage"});
