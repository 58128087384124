import { useContext } from 'react';
import { ClientConfigType, MyChartRedirectBaseUrl } from '../../clientConfig';
import { ClientConfigContext } from '../clientConfigContext';
import { useBrand } from './useBrand';
import { getRedirectUrl } from '../../utils/redirectUtil';

type MyChartRedirect = {
    getLandingPage: (ehrSystem: string) => string;
    getSamlSsoPage: (ehrSystem: string) => string;
}

const useMyChartRedirect = (): MyChartRedirect => {
    const clientConfiguration = useContext<ClientConfigType>(ClientConfigContext);
    const { brand } = useBrand(); // may throw error on bad brand name and no default is set.

    const getBaseURL = (ehrSystem: string, brand: string): MyChartRedirectBaseUrl => {
        const myChartRedirectPages = clientConfiguration.myChartRedirectBaseUrls;
        const baseUrlResult = myChartRedirectPages.find(page => page.brand === brand && page.ehrSystem === ehrSystem);
        if (!baseUrlResult) {
            throw new Error(`Redirect Urls not found for the brand "${brand}" and ehrSystem "${ehrSystem}". `);
        }
        return baseUrlResult;
    }

    const getSamlSsoPage = (ehrSystem: string) => getRedirectUrl({
        getBaseURL: (ehrSystem: string, brand: string) => getBaseURL(ehrSystem, brand).samlSsoPage,
        brand,
        ehrSystem
    })

    const getLandingPage = (ehrSystem: string) => getRedirectUrl({
        getBaseURL: (ehrSystem: string, brand: string) => getBaseURL(ehrSystem, brand).landingPage,
        brand,
        ehrSystem
    })

    return { getSamlSsoPage, getLandingPage };
}

export { useMyChartRedirect, MyChartRedirect };
