import React, {useContext, useEffect, useState, useRef} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {Grid, Typography, CircularProgress} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {useMsal} from "@azure/msal-react";

import getAccessToken from "../../../../util/getAccessToken";
import TwoButtonContainer from '../../../containers/TwoButtonContainer';
import BrandedCard from '../../../containers/BrandedCard/BrandedCard';
import verificationCodeControl from '../../../controls/VerificationCodeControl';
import {verifyCode, sendVerificationCode} from '../../../../services/pimsClient';
import {ClientConfigContext} from "../../../../clientConfigContext";
import { type BrandProps, type ClientConfigType } from "../../../../../clientConfig";
import {sendEventbyIdVerification} from '../../../../services/amplitudeClient';
import {LocationState} from "../../../../../types/reactTypes";
import withLogger, { ComponentLoggerProps } from '../../../HOC/WithLogger';
import { AmplitudeEventType } from '../../../../../analytics/amplitudeEvents';

function VerifyPhoneCode({brandInfo, log}: BrandProps & ComponentLoggerProps) {
    const {instance, accounts} = useMsal();
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const history = useHistory();
    const location = useLocation<LocationState>();
    const {handleSubmit, errors, control, formState} = useForm({mode: 'onChange'});
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const isMountedRef = useRef(true);

    const {t, i18n} = useTranslation();
    const [jwt, setJwt] = useState("");
    const [verificationAttempts, setVerificationAttempts] = useState(0);

    useEffect(() => {
        return () => {
            isMountedRef.current = false
        }
    }, []);
    useEffect(() => {
        setPhoneNumber(location.state?.phoneNumber);
    }, [location]);

    useEffect(() => {getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log).then((jwt)=>{
        setJwt(jwt);
         sendEventbyIdVerification(jwt, AmplitudeEventType.IdentityVerification_VerificationCode, 'sms');
    });
    }, []);
    useEffect(() => {
        !invalid && sendEventbyIdVerification(jwt, AmplitudeEventType.IdentityVerification_VerificationCode_Invalid, 'sms');
    }, [invalid]);


    const onSubmit = async (data: any) => {
        try {
            setInvalid(false);
            const response = await verifyCode({
                jwt,
                code: data.verificationCode,
                pimsApiUrl: clientConfiguration.pimsApiUrl,
                proveBypass: clientConfiguration.bypassProve
            });
            if (response.ok) {
                const responseBody = await response.json();
                const proveBypass = (responseBody.state === "bypass")? "bypass" : undefined;
                history.push({
                    pathname: "/demographics-intro",
                    state: {
                        phoneNumber: phoneNumber,
                        proveBypass: proveBypass,
                        isCrossRegion: location?.state?.isCrossRegion
                    }
                });
            } else {
                setVerificationAttempts(verificationAttempts + 1);
                if (verificationAttempts >= 2) { // Max attempts reached at 3 retries. Index starting at 2.
                    log.warn('max verification attempt reached');
                    history.push("/error");
                } else {
                    setInvalid(true);
                    log.warn("verification code invalid.");
                }
            }
        } catch (err: any) {
            log.error(`Error occurred - ${err.message}`);
            history.push("/error");
        }
    };

    const resendVerification = async (event: any) => {
        setVerificationAttempts(0); // Clear the verification attempts
        sendEventbyIdVerification(jwt, AmplitudeEventType.IdentityVerification_RequestNewVerificationCode, 'sms');
        event.preventDefault();
        if (isMountedRef.current) {
            setLoading(true);
        }
        const numberNoDashes = phoneNumber.replace(/[-]/g, "");
        try {
            setInvalid(false);
            const response = await sendVerificationCode({
                jwt, value: numberNoDashes, pimsApiUrl: clientConfiguration.pimsApiUrl, language: i18n.languages[0]
            });
            if (response.ok) {
                if (isMountedRef.current) {
                    setLoading(false);
                }
            } else {
                log.error(`Error occurred - ${response.status}${response.statusText ? ` -  ${response.statusText}`: ''}`);
                history.push("/error");
            }
        } catch (err: any) {
            log.error(`Error occurred - ${err.message}`);
            history.push("/error");
        }
    };

    const {isDirty, isValid} = formState;

    const renderContent = () => {
        if (loading) {
            return (
                <>
                    <Grid item xs={12}>&nbsp;</Grid>
                    <Grid container justify="center" item xs={12}>
                        <CircularProgress/>
                    </Grid>
                </>
            );
        } else {
            return (
                <Grid container justify="center" item xs={12}>
                    <Grid container item xs={11}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className="txtAlignCenter">
                                {t("Enter the verification code that was sent to")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h3" className="txtAlignCenter">
                                {phoneNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="h60">
                        &nbsp;
                    </Grid>
                    <Grid container spacing={2} item xs={8}>
                        {verificationCodeControl(control, errors, t, undefined, invalid)}
                    </Grid>
                    <Grid item xs={12} className="h80">
                        &nbsp;
                    </Grid>
                    <Grid container justify="center" item xs={10} className="txtAlignCenter">
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                {t("The code will be active for 5 minutes")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                <Link className="txtDecorationNone" to="/verification-code/reload"
                                      onClick={(e) => resendVerification(e)}>{t('Request new verification code')}</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }

    const renderActions = () => {
        const handleCancelClick= () =>{
            sendEventbyIdVerification(jwt, AmplitudeEventType.IdentityVerification_VerificationCode_Back, 'sms');
            history.push({
                pathname: "/phone", 
                // If we arrive here without this is_cross_region query param, we need to propagate it to the next page via the state.
                // TODO: Replace all this propagation with a React context
                state: { isCrossRegion: location?.state?.isCrossRegion } 
            });
        }
        if (loading) {
            return (
                <>
                </>
            );
        } else {
            return (
                <Grid container justify="space-around" item xs={12}>
                    <TwoButtonContainer
                        firstButtonProps={{
                            variant: "text",
                            label: t("Cancel"),
                            brand: brandInfo.brand,
                            onClick: handleCancelClick
                        }}
                        secondButtonProps={{
                            disabled: !isDirty || !isValid,
                            variant: "contained",
                            label: t("Next"),
                            brand: brandInfo.brand
                        }}
                    />
                </Grid>
            );
        }
    }

    return (
        <>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <BrandedCard brand={brandInfo}>
                        {{
                            header: (<>
                                <Typography variant="h2">
                                    {t('Check your SMS text messages')}
                                </Typography>
                            </>),
                            content: (<>
                                {renderContent()}
                            </>),
                            actions: (<>
                                    {renderActions()}
                                </>
                            )
                        }}
                    </BrandedCard>
                </form>
            </div>
        </>
    );

}

export default withLogger(VerifyPhoneCode, {eventSource: 'VerifyPhoneCode'});
