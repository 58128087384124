import React, { ReactNode } from 'react';
import { Typography } from "@material-ui/core";
import { useTranslation, UseTranslationResponse } from "react-i18next";
import { Variant } from '@material-ui/core/styles/createTypography';
import { TFunction } from 'i18next';

type TypographyI18NProps = {
    className?: string,
    variant?: Variant,
    children: ReactNode
}

const TypographyI18N = (props: TypographyI18NProps) => {
    const { children, className, variant = 'body1' } = props;
    const translation: UseTranslationResponse<string> = useTranslation();
    const translationFn: TFunction = translation.t;

    let content: ReactNode = children;
    if (typeof children === 'string') {
        const translatedText = translationFn(children);
        content = translatedText || children;
    }   

    return <Typography className={className} variant={variant}>{content}</Typography>;
}

TypographyI18N.displayName = 'TypographyI18N';
export default TypographyI18N;
