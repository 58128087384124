import React from 'react';
import Grid from '@material-ui/core/Grid';

import activationCodeControl from '../../controls/ActivationCodeControl';
import dateOfBirthControl from '../../controls/DateOfBirthControl';
import last4SsnControl from '../../controls/Last4SsnControl';
import {BrandConfig} from "../../../../clientConfig";

export type ActivationCodeFormProps = {
  control: any,
  errors: any,
  translation: any,
  brandInfo: BrandConfig
}

function ActivationCodeForm(props: ActivationCodeFormProps): JSX.Element {
    return (
        <Grid container spacing={9}>
            <Grid container justify="center" item xs={12}>
                <Grid item xs={11} sm={8}>
                </Grid>
                <Grid item xs={11} sm={8}>
                    {activationCodeControl(props.control, props.errors)}
                </Grid>
                <Grid item xs={11} sm={8}>
                </Grid>
                <Grid item xs={11} sm={8}>
                    {dateOfBirthControl(props.control, props.errors, undefined)}
                </Grid>
                <Grid item xs={11} sm={8}>
                </Grid>
                <Grid item xs={11} sm={8}>
                    {last4SsnControl(props.control, props.errors, true, undefined)}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ActivationCodeForm;
