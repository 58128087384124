import { ClientContext, DeviceCategory, DeviceType, MobileOS } from "../../clientConfig";
import { OidcState } from "../../types/oidcState";
import { OPENID_PARAMS } from "../../types/openid_enum";
import { ApiRequestLogger } from "../logger/apiRequestLogger";

/**
 * Returns the mobile operating system (or unknown)
 * @param logger Optional logger to log unknown OS scenarios.
 */
export function getMobileOperatingSystem(logger?: ApiRequestLogger): MobileOS {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
        return "Android";
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    } else {
        if (logger) {
            logger.warn(`getMobileOperatingSystem(): UserAgent - ${userAgent} returned unknown OS.`);
        }
        return "unknown";
    }
}

/**
 * Returns the device category (Desktop or Mobile). Optional: Can also prefer the 'deviceType' query parameter.
 */
export function getDeviceCategory(useQueryParam = false): DeviceCategory {

    if (useQueryParam) {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('deviceType')) {
            return searchParams.get('deviceType') as DeviceCategory;
        }
    }

    if (/Mobi|Mobile|Android|iPhone|iPad|iPod|Windows Phone|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return "Mobile";
    } else {
        return "Desktop";
    }
}

type GetDeviceTypeOptions = {
    useQueryParam?: boolean;
    logger?: ApiRequestLogger;
}

/**
 * Returns the device type (Desktop, Android, iOS, or unknown)
 */
export function getDeviceType({useQueryParam, logger}: GetDeviceTypeOptions = {}): DeviceType {

    const deviceCategory = getDeviceCategory(useQueryParam);

    if ( deviceCategory === "Mobile" ){
        return getMobileOperatingSystem(logger);
    } else {
        return deviceCategory;
    }
}

/**
 * Get the state from the URL query parameters.
 */
export const getOidcState = <T extends OidcState> (inputSearchParams: URLSearchParams): T | undefined => {
    const searchParams = inputSearchParams ?? new URLSearchParams(window.location.search);
    const encodedState = searchParams.get(OPENID_PARAMS.STATE) as string;

    if (!encodedState) {
        return undefined;
    }

    try {
        const stringState = atob(encodedState); // Buffer.from(encodedState, "base64").toString() apparently not supported by browsers yet
        return JSON.parse(stringState) as T;
    } catch (err: any) {
        console.error('Error parsing OIDC state', err?.message);
    }
}

/**
 * Fetch brand, ehrSystem, deviceCategory, and deviceType from the query parameters or OIDC state.
 */
export const getClientContext = (): ClientContext => {
    const searchParams = new URLSearchParams(document.location.search);
    const oidcState = getOidcState(searchParams);
    
    const clientContext: ClientContext = {};

    // Brand
    if (searchParams.get(OPENID_PARAMS.BRAND)) {
        clientContext.brand = searchParams.get(OPENID_PARAMS.BRAND) as string;
    } else if (oidcState?.[OPENID_PARAMS.BRAND]) {
        clientContext.brand = oidcState[OPENID_PARAMS.BRAND];
    }

    // Device Category
    const deviceCategory = getDeviceCategory(true);
    if (deviceCategory) {
        clientContext.deviceCategory = deviceCategory;
    }

    // Device Type
    const deviceType = getDeviceType({ useQueryParam: true });
    if (deviceType) {
        clientContext.deviceType = deviceType;
    }

    // EHR System
    if (searchParams.get(OPENID_PARAMS.EHR_SYSTEM)) {
        clientContext.ehrSystem = searchParams.get(OPENID_PARAMS.EHR_SYSTEM) as string;
    } else if (oidcState?.[OPENID_PARAMS.EHR_SYSTEM]) {
        clientContext.ehrSystem = oidcState[OPENID_PARAMS.EHR_SYSTEM];
    }

    return clientContext;
}

/**
 * Generate a query string from the client context.
 */
export const toQueryString = (clientContext: ClientContext): string => {
    const urlSearchParams = new URLSearchParams();
    Object.keys(clientContext).forEach((key) => {
        urlSearchParams.set(key, clientContext[key as keyof ClientContext] as string);
    });
    return urlSearchParams.toString();
}