import React, {useContext, useEffect, useState} from 'react';

import VerifyEntry from '../verification/VerifyEntry';
import getAccessToken from "../../../util/getAccessToken";
import {useMsal} from "@azure/msal-react";
import {ClientConfigContext} from "../../../clientConfigContext";
import {getConsumer} from "../../../services/pimsClient";
import {useHistory} from "react-router-dom";
import {hasAuthorizedSystem, pickAuthorizedSystem} from "../../../../utils/consumerUtil";
import {isRedirectUrlValid} from "../../../services/validation";
import {AUTO_INSTANT_ACTIVATION_PARAMS, OPENID_PARAMS} from "../../../../types/openid_enum";
import { setUserId } from '../../../services/amplitudeClient';
import {redirectWithSoftLogout} from "../../../util/sessionTimeout";
import { getRedirectUrl } from '../../../../utils/redirectUtil';
import { useOidcState } from "../../../hooks/useOidcState";
import { AIAState } from "../../../../types/aiaState";
import { useBrand } from "../../../hooks/useBrand";
import withLogger, { ComponentLoggerProps } from '../../HOC/WithLogger';
import { useFeature } from '../../../hooks/useFeature';
import { Feature } from '../../../../types/feature';
import IdentityVerificationPage from '../verification/IdentityVerificationPage';
import useCookie from '../../../hooks/useCookie';
import { CLEAR_SESSION_COOKIE_PREFIX } from '../verification/clear';
import { AuthenticatedRoutes } from '../../routers';

/**
 * This is the entry point into the MyChart authorization forms (authorization code and demographics).
 * This page is public and secured. It accepts the end-user JWT and retrieves the PIMS consumer record.
 *
 * If the consumer is MyChart authorized, then we redirect the end-user back to the redirection endpoint.
 * If the consumer is not authorized, or not authorized in the EHR system specified in the query, ehr_system (optional),
 * then we proceed to the register form.
 *
 * From other client-apps, register?brand=brand1&redirect_uri=<client_redirect_url>&ehr_system=&mobile=true
 * From other client-apps, register?brand=brand2&redirect_uri=<client_redirect_url2>&ehr_system=ehr1&mobile=true
 *
 * From Mychart app, register?brand=allProvidence&redirect_uri=SSO_url_for_noncross_region&cancel_redirect_uri=landing_page&ehrSystem=provwamt
 */
function RegisterPage({ log }: ComponentLoggerProps) {
    const {instance, accounts} = useMsal();
    const {
        b2cTenantConfig,
        myChartRedirectBaseUrls,
        pimsApiUrl
    } = useContext(ClientConfigContext);
    const [ renderPage, setRenderPage ] = useState(false);
    const history = useHistory();
    const decodedState = useOidcState<AIAState>({ isRequired: false, log, errorPath: '/error', onError: () => { return; } });
    let { brand } = useBrand();
    const search = document.location.search;
    const searchParams = new URLSearchParams(search);
    let redirectUrl = searchParams.get(OPENID_PARAMS.REDIRECT_URI) || searchParams.get(OPENID_PARAMS.REDIRECT_URL) as string;
    let goToRegisterPhonePage = searchParams.get(AUTO_INSTANT_ACTIVATION_PARAMS.REGISTER_PHONE)?.toLocaleLowerCase() === "true";
    let ehrSystem = searchParams.get(OPENID_PARAMS.EHR_SYSTEM) as string;
    let isCrossRegion: boolean = searchParams.get(OPENID_PARAMS.CROSS_REGION) === "true";

    // Show the new Identity Verification page if the user already has a clear session cookie or if the feature is enabled
    const azureUserId = accounts?.[0]?.localAccountId || "";
    const clearSessionCookieName = `${CLEAR_SESSION_COOKIE_PREFIX}_${azureUserId}`;
    const [clearSessionId] = useCookie(clearSessionCookieName);
    const { isEnabled: isClearFeatureEnabled } = useFeature(Feature.ClearIdentityVerification);
    const showIdentityVerificationPage = clearSessionId || isClearFeatureEnabled;

    // Get the appropriate base url for the brand/ehrSystem
    const getBaseURL = (ehrSystem: string, brand: string): string => {
        const baseUrlResult = myChartRedirectBaseUrls.find(page => page.brand === brand && page.ehrSystem === ehrSystem);
        if (!baseUrlResult) {
            throw new Error(`Redirect Url not found for the brand "${brand}" and ehrSystem "${ehrSystem}". `);
        }
        return baseUrlResult.samlSsoPage;
    }

    useEffect(() => {
        // look into the state query param and repopulate
        if (!redirectUrl && decodedState) {
            redirectUrl = decodedState.redirect_uri as string;
            brand = decodedState.brand as string;
            ehrSystem = decodedState.ehrSystem as string;
            isCrossRegion = decodedState.is_cross_region || false;
            goToRegisterPhonePage = decodedState.register_phone || false;
        }

        //Set the userID once so that it can be used for all Amplitude events for specific user
        setUserId(accounts?.[0]?.localAccountId)
        if (!redirectUrl) {
            log.error(`No redirect_uri for register flow.`);
            history.push("/error");
            return;
        }

        if (goToRegisterPhonePage) {
            history.push({
                pathname: AuthenticatedRoutes.VerificationRoutes.StartDemographicsFlow,
                // If we arrive here without this is_cross_region query param, we need to propagate it to the next page via the state.
                // TODO: Replace all this propagation with a React context
                state: { isCrossRegion } 
            });
            return;
        }

        const getConsumerAsync = async () => {
            const isValid = await isRedirectUrlValid(redirectUrl);
            if (!isValid) {
                log.error(`Invalid redirect URL: ${redirectUrl}`);
                const queryStart = redirectUrl.includes("?")? ":" : "?";
                const newRedirectUrl = redirectUrl + queryStart + "error=redirect_uri_mismatch";
                redirectWithSoftLogout(instance, newRedirectUrl);
                return;
            }

            try {
                const jwt = await getAccessToken(instance, accounts, b2cTenantConfig.scopes, log);
                const consumer = await getConsumer(jwt, pimsApiUrl);

                // base case 1: when the account is authorized, we redirect the patient out of the registration
                // case 2: otherwise, they stay on the registration workflow
                const isAuthorized = hasAuthorizedSystem(consumer, ehrSystem);
                if (isAuthorized) { // already authorized. User should be redirected back to their redirect_uri
                    let finalRedirectUrl = redirectUrl;

                    if (isCrossRegion) { // For MyChart apps, the cross_region flag is set to true so that it applies cross-region logic.
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        const authorizedEhrSystem: string = pickAuthorizedSystem(consumer, ehrSystem)!;
                        finalRedirectUrl = getRedirectUrl({
                            getBaseURL,
                            brand,
                            ehrSystem: authorizedEhrSystem
                        });
                    }
                    redirectWithSoftLogout(instance, finalRedirectUrl);
                } else {
                    setRenderPage(true);
                }
            } catch (err: any) {
                log.error(`getConsumerAsync error: ${err.message}. Proceed to registration`);
                setRenderPage(true);
            }
        };
        getConsumerAsync();
    }, []);

    if (renderPage) {
        if (showIdentityVerificationPage) {
            return <IdentityVerificationPage isCrossRegion={isCrossRegion} />
        } else {
            return <VerifyEntry isCrossRegion={isCrossRegion} />
        }
    } else {
        return null;
    }
}

export default withLogger(RegisterPage, { eventSource: "RegisterPage" });
