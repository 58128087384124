import React,{useContext, useEffect, useState} from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { type ClientConfigType } from "../../../../clientConfig";
import { sendEventByEventObject, sendEventByType } from '../../../services/amplitudeClient';
import { useMsal } from '@azure/msal-react';
import getAccessToken from '../../../util/getAccessToken';
import { ClientConfigContext } from '../../../clientConfigContext';
import BrandedCard, { BrandedCardActions, BrandedCardContent } from '../../containers/BrandedCard_v2';
import Button from "../../elements/Button";
import TypographyI18N from "../../elements/TypographyI18N";
import withLogger, { ComponentLoggerProps } from '../../HOC/WithLogger';
import { AmplitudeEventType } from '../../../../analytics/amplitudeEvents';
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { ClearButton, VENDOR_NAME as CLEAR_VENDOR_NAME, ClearConfig } from './clear';
import { AuthenticatedRoutes } from '../../routers';

type IdentityVerificationPageProps = ComponentLoggerProps & {
    isCrossRegion?: boolean;
}

enum PageState {
    Loading,
    NewSession,
    ExistingSession,
    Redirecting
}

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: 20
    },
    list: {
        padding: 0,
    },
    listItem: {
        display: "list-item",
        left: 12,
        listStylePosition: "inside",
        listStyleType: "disc",
        marginTop: 0,
        padding: 0,
        position: "relative",
        '&::marker': {
            fontSize: theme.typography.subtitle1.fontSize
        }
    },
    listItemText: {
        margin: 0,
        display: "inline-flex",
        whiteSpace: "break-spaces",
        width: "calc(100% - 28px)"
    },
    listItemTypography: {
        lineHeight: '24px'
    }
}));

/**
 * The Identity Verification Page.
 * Basic identities will arrive at this page when they need an EHR authorization.
 * @param isCrossRegion 
 * @param log APIRequestLogger
 */
export const IdentityVerificationPage: React.FC<IdentityVerificationPageProps> = ({ isCrossRegion = false, log }: IdentityVerificationPageProps) => {
    const history = useHistory();
    const [jwt, setJwt] = useState("");
    const {instance, accounts} = useMsal();
    const { b2cTenantConfig, identityVerification }: ClientConfigType = useContext(ClientConfigContext);
    const [pageState] = useState<PageState>(PageState.NewSession);

    useEffect(()=> {
        getAccessToken(instance, accounts, b2cTenantConfig.scopes, log).then((jwt)=>{
            setJwt(jwt);
            const eventPropsData  = {
                event_type: AmplitudeEventType.ActivationCode_Prerequisite_Question,
                event_properties: {
                    entry_flow: accounts[0]?.idTokenClaims?.newUser ? "New Account creation" : "Existing Account"
                }
            }
            sendEventByEventObject(jwt, eventPropsData);
        });
    },[]);


    const useDemographicsFlow = () =>{
        sendEventByType(AmplitudeEventType.ActivationCode_Prerequisite_Deny, jwt);
        history.push({
            pathname: AuthenticatedRoutes.VerificationRoutes.StartDemographicsFlow,
            // If we arrive here without this is_cross_region query param, we need to propagate it to the next page via the state.
            // TODO: Replace all this propagation with a React context
            state: { isCrossRegion } 
        });
    }

    const useActivationCodeFlow = () =>{
        sendEventByType(AmplitudeEventType.ActivationCode_Prerequisite_Confirm, jwt);
        history.push(AuthenticatedRoutes.VerificationRoutes.ActivationCodeFlow);
    }

    const clearConfig: ClearConfig = identityVerification.find((option) => option.vendor === CLEAR_VENDOR_NAME)?.options;

    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <BrandedCard maxWidth={452} minHeight={530} isLoading={pageState === PageState.Loading} >
            <BrandedCardContent marginTop={48} paddingLeft={48} paddingRight={48}>
                <TypographyI18N variant="subtitle1" className={classes.header}>To complete sign-up, choose one of the following options:</TypographyI18N>
                <List className={classes.list}>
                    <ListItem className={classes.listItem}>
                        <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={
                                <TypographyI18N
                                    variant='subtitle1'
                                    className={classes.listItemTypography}
                                >Continue with our partner, CLEAR</TypographyI18N>
                            } />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={
                                <TypographyI18N
                                    variant='subtitle1'
                                    className={classes.listItemTypography}
                                >Enter the activation code provided by one of our clinics</TypographyI18N>
                            } />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={
                                <TypographyI18N
                                    variant='subtitle1'
                                    className={classes.listItemTypography}
                                >Use the basic activation process</TypographyI18N>
                            } 
                        />
                    </ListItem>
                </List>
            </BrandedCardContent>
            <BrandedCardActions direction='column' gap={16} height={214} justifyContent='flex-start'>
                <ClearButton width={240} newSession={pageState === PageState.NewSession} config={clearConfig} />
                <Button backgroundColor={"initial"} variant="outlined" width={240} paddingLeft={0} paddingRight={0} onClick={useActivationCodeFlow}>{t('Use Activation Code')}</Button>
                <Button backgroundColor={"initial"} variant="outlined" width={240} paddingLeft={0} paddingRight={0} onClick={useDemographicsFlow}>{t('Basic Activation')}</Button>
            </BrandedCardActions>
        </BrandedCard>
    );
}

export default withLogger(IdentityVerificationPage, { eventSource: "IdentityVerificationPage"});
