import { useContext } from 'react';
import { type ClientConfigType } from '../../clientConfig';
import { ClientConfigContext } from '../clientConfigContext';
import { Feature } from '../../types/feature';

type FeatureConfig = {
    isEnabled: boolean;
}

// Determine if a feature is enabled or not
export const useFeature = (name: Feature): FeatureConfig => {
    const clientConfiguration = useContext<ClientConfigType>(ClientConfigContext);
    const features = clientConfiguration.features;
    const feature = features?.find(f => f.name === name);

    return {
        isEnabled: feature?.enabled ?? false,
    }
}
