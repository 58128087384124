import React, { ReactNode } from 'react';

import Logo from '../../elements/Logo';
import { Grid, makeStyles } from '@material-ui/core';

import LegalLinks from '../../elements/LegalLinks';
import { type BrandConfig } from "../../../../clientConfig";

export type BrandedCardProps = {
    brand: BrandConfig,
    showLogo?: boolean,
    maxWidth?: number,
    children: {
        header: ReactNode,
        content: ReactNode,
        actions?: ReactNode,
    },
}

const useStyles = makeStyles((theme) => ({
    helmet: {
        background: theme.palette.primary.dark,
        display: "block",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        height: 40,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10
    },
}));

function BrandedCard(props: BrandedCardProps) {
    const classes = useStyles();

    let maxWidth = 452;
    if (props.maxWidth) {
        maxWidth = props.maxWidth
    }

    const { header, content, actions } = props.children;
    const headerContent = (
        <>
            {header}
        </>
    );

    const actionsContent = (
        <>
            {actions}
        </>
    );

    const noStyle = {};

    const renderHelmet = () => {
        return (
            <Grid item xs={12} className={classes.helmet}>&nbsp;</Grid>
        );
    };

    return (
        <Grid container justify="center" alignItems="stretch" direction="column" className="background" style={noStyle}>
            <Grid item xs={12} style={noStyle}>
                <Grid container justify="center" direction="row" style={noStyle}>
                    <Grid item xs={12} style={noStyle}>&nbsp;</Grid>
                    <Grid style={{maxWidth: maxWidth, paddingLeft: 0, paddingRight: 0}} container alignItems="center" alignContent="center" item xs={11}>
                        <Grid container item xs={12} direction="row" justify="center" alignItems="center" alignContent="flex-start" className="cardMainContentOuter">
                            {renderHelmet()}
                            <Grid container justify="center" alignItems="center" alignContent="space-between" className="brandedCardContainer">
                                <Grid container spacing={3} justify="center" alignItems="center" alignContent="space-between" item xs={12} className="cardMainContentInner">
                                    <Grid container spacing={3} item xs={12}>
                                        {props.showLogo && <Grid container justify="center" item xs={12}>
                                          <Logo size={props.brand.logoSize ? props.brand.logoSize : 200} imageUri={props.brand.logoUri} altText={props.brand.logoAltText} /></Grid>}
                                        {header && <Grid container justify="center" item xs={12} className="headerText"><>{headerContent}</></Grid>}
                                    </Grid>
                                    <Grid container justify="center" item xs={12} className="actualContent">{content}</Grid>
                                    <Grid container spacing={2} item xs={12} className="pdl0 pdr0">{actionsContent}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={noStyle} container justify="center" item xs={11}>
                        <LegalLinks brandInfo={props.brand} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BrandedCard;
