import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { ReactNode } from 'react';

type BrandedCardActionsStyles = {
    gap?: string | number;
    height?: number;
    justifyContent?: 'flex-start' | 'space-between' | 'space-evenly';
}

const useStyles = makeStyles<Theme, BrandedCardActionsStyles, string>(() => ({
    root: {
        alignItems: "center",
        gap: options => options.gap,
        height: options => options.height,
        justifyContent: options => options.justifyContent,
        paddingLeft: 60,
        paddingRight: 60,
    },
}));

export type BrandedCardActionsProps = BrandedCardActionsStyles & {
    children: ReactNode | ReactNode[],
    direction?: 'row' | 'column',
    isArray?: boolean
}

const BrandedCardActions = ({
    children,
    direction='row',
    gap,
    height,
    isArray, 
    justifyContent
}: BrandedCardActionsProps) => {

    const styles = {
        gap: gap ?? 'inherit',
        height: height ?? 104,
        justifyContent: justifyContent ?? (isArray ? "space-between" : "space-evenly"),
        isArray: Array.isArray(children)
    };

    const classes = useStyles(styles);
    return (
        <Grid item container wrap='nowrap' className={classes.root} direction={direction} xs={12}>
            {children}
        </Grid>
    );
}
BrandedCardActions.displayName = 'BrandedCardActions';
export default BrandedCardActions;
