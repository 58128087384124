import React, {useState, useContext, useEffect} from 'react';
/*eslint-disable*/
import Loader from 'react-loader';
/*eslint-enable*/
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { Grid, Typography } from "@material-ui/core";

import Button from "../../elements/Button";
import BrandedCard from '../../containers/BrandedCard/BrandedCard';
import CollectDemographicsForm from './CollectDemographicsForm';
import {ClientConfigContext} from "../../../clientConfigContext";
import {isRedirectUrlValid} from "../../../services/validation";
import {linkWithDemographics} from "../../../services/pimsClient";
import getAccessToken from "../../../util/getAccessToken";
import {Demographics} from "../../../../types/demographics";
import {SpinnerProperties} from '../../../../assets/themes/spinnerProperties';
import {useMsal} from "@azure/msal-react";
import {BrandProps, ClientConfigType} from "../../../../clientConfig";
import {OPENID_PARAMS} from "../../../../types/openid_enum";
import {redirectWithSoftLogout} from "../../../util/sessionTimeout";
import withLogger, { ComponentLoggerProps } from '../../HOC/WithLogger';

function LinkWithDemographics({brandInfo, log}: BrandProps & ComponentLoggerProps) {

    const search = document.location.search;
    const searchParams = new URLSearchParams(search);
    const system = searchParams.get(OPENID_PARAMS.EHR_SYSTEM) || searchParams.get(OPENID_PARAMS.SYSTEM) as string;
    const locationState = searchParams.get(OPENID_PARAMS.LOCATION_STATE);
    const redirectUrl = searchParams.get(OPENID_PARAMS.REDIRECT_URI) || searchParams.get(OPENID_PARAMS.REDIRECT_URL) as string;
    const cancelUrl = searchParams.get(OPENID_PARAMS.CANCEL_REDIRECT_URI);

    const {instance, accounts} = useMsal();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const {t} = useTranslation();
    const { handleSubmit, errors, control, formState } = useForm({ mode: 'onChange' });
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const [ isValidRedirectUrl, setIsValidRedirectUrl ] = useState(false);

    const getHeader = () => {
        if (loading) {
            return (
                <>
                    <Typography variant="h2">
                        {t('Creating your account')}
                    </Typography>
                </>
            );
        } else {
            return (
                <>
                    <Typography variant="h2">
                        {t('Help us find your medical record')}
                    </Typography>
                </>
            );
        }
    };

    const callCancelUrl = () => {
        if (cancelUrl) {
            redirectWithSoftLogout(instance, cancelUrl);
        }
    };

    const renderCancelButton = () => {
        if (cancelUrl) {
            return (
                <button className="cancelButton" type="button" onClick={callCancelUrl}>
                    {t('Cancel')}
                </button>
            );
        }
    };

    const getActions = () => {
        if (loading) {
            return <></>;
        } else {
            return <>
                <Grid container justify="space-around" item xs={12}>
                    <Grid className="mh144" container justify="center" item xs={3}>
                        {renderCancelButton()}
                    </Grid>
                    <Grid className="mh144" container justify="center" item xs={3}>
                        <Button disabled={!isDirty || !isValid} type="submit" variant="contained">{t('Next')}</Button>
                    </Grid>
                </Grid>
            </>
        }
    };

    const validateInputParameters = async () => {
        if (!system && !locationState) {
            log.warn( "Missing system or locationState query parameter");
            history.push("/error");
        } else if (!redirectUrl) {
            log.warn(`Missing redirect_uri`);
            history.push("/error");
        } else {
            const isValid = await isRedirectUrlValid(redirectUrl);
            if (!isValid) {
                log.warn(`invalid redirect_uri provided`);
                history.push("/error");
            } else {
                setIsValidRedirectUrl(true);
            }
        }
    };

    const onSubmit = async (data: any) => {
        const demographics:Demographics = {
            firstName: data.firstName,
            lastName: data.lastName,
            gender: data.gender,
            dateOfBirth: data.birthdate,
            phoneNumber: data.cellPhoneNumber,
            streetAddress: data.streetAddress,
            city: data.city,
            state: data.state,
            postalCode: data.zipCode,
        };
        if (!system) {
            demographics.locationState = locationState
        }
        if (data.ssn) {
            demographics.last4ssn = data.ssn;
        }
        setLoading(true);
        try {
            const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log);
            const response = await linkWithDemographics({jwt, demographics, pimsApiUrl: clientConfiguration.pimsApiUrl, system});
            if (response.ok) {
                if (isValidRedirectUrl && redirectUrl) {
                    window.location.replace(redirectUrl);
                }
            } else {
                setLoading(false);
                log.error(`LinkWithDemographics failed. Error occurred - ${response.status}${response.statusText ? ` -  ${response.statusText}`: ''}`);
                history.push("/error");
            }
        } catch (err: any) {
            setLoading(false);
            log.error(`LinkWithDemographics failed. Error occurred - ${err.message}`);
            history.push("/error");
        }
    };

    const { isDirty, isValid } = formState;

    useEffect(() => {
        window.scrollTo(0, 0);
        validateInputParameters();
    },[]);

    return (
        <>
            <div>
                <form onSubmit={handleSubmit(onSubmit)} className="displayFlex">
                    <BrandedCard maxWidth={loading ? 512 : 711} brand={brandInfo}>
                        {{
                            header: (getHeader()),
                            content: (<>
                                <Loader loaded={!loading} options={SpinnerProperties}>
                                    <CollectDemographicsForm control={control} errors={errors} infoComponent={""}/>
                                </Loader>
                            </>),
                            actions: (getActions())
                        }}
                    </BrandedCard>
                </form>
            </div>
        </>
    );
}

export default withLogger(LinkWithDemographics, { eventSource: "LinkWithDemographics"});
